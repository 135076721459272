import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/dashboard",
  },
  {
    id: "contact-us",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Mail size={16} />,
    navLink: "/contact-us",
  },
];

export default navigationConfig;
